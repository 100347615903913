<ng-template #actionTemplate>

  @if (!overflow) {
  <div matTooltip="{{name() | translate}}" [class.disabled]="disabled()" (click)="handleClick()">
    <i class="ai-icon-{{icon()}} tuula-action-icons-table"></i>
  </div>
  } @else {
  <div mat-menu-item class="c4p-menu-item tuula-action-btn-wrapper" [class.disabled]="disabled()" (click)="handleClick()">
    <i class="ai-icon-{{icon()}} tuula-action-icon"></i>
    <span class="tuula-action-text">{{name() | translate}}</span>
  </div>
  }
</ng-template>
